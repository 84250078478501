<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      okText="Add"
      title="Add Payment Method"
      :visible="visible"
      @cancel="$emit('close')"
      @ok="onSubmit"
    >
      <div class="ant-input mb-4" id="card-element"></div>
      <small>
        <p>Payments are processed securely through <a href="https://stripe.com" target="_blank">Stripe</a>. You authorise Vatstack to send instructions to the financial institution that issued your card to take payments from your card account in accordance with the terms of your agreement with us.</p>
      </small>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      name: this.$store.state.user.name,
      email: this.$store.state.user.email,
      stripeCard: null,
    }
  },

  computed: {
    stripe() {
      return Stripe(process.env.VUE_APP_STRIPE)
    },
  },

  mounted() {
    var elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400' }]
    })

    this.stripeCard = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          color: '#1b004c',
          fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
        },
      },
    })
  },

  watch: {
    visible(newValue, oldValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.stripeCard.mount('#card-element')
        }, 250)
      }
    },
  },

  methods: {
    async onSubmit() {
      this.loading = true

      /**
       * Generate a setup intent hash through the backend.
       */
      try {
        var { data } = await this.$httpd.post('/methods/intent')
      } catch (err) {
        this.$message.error(err.message)
        this.loading = false

        return false
      }

      try {
        var { setupIntent } = await this.stripe.confirmCardSetup(data.client_secret, {
          payment_method: { card: this.stripeCard }
        })
      } catch (err) {
        this.$message.error(err.error.message)
        this.loading = false

        return false
      }

      try {
        var { data } = await this.$httpd.post(`/methods`, {
          payment_method: setupIntent.payment_method,
        })

        this.$emit('success')
        this.$message.success('Payment method has been added.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.StripeElement {
  -webkit-appearance: none;
  background-color: #fff;
  box-sizing: border-box;
  outline: 0;
  padding-top: 9px;
}

.StripeElement--focus {
  border-color: #4e1fbf;
}

.StripeElement--invalid {
  border-color: #F56C6C;
}
</style>
