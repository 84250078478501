<template>
  <a-form @submit.prevent.native="onChange">
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <strong>Subscription Plan</strong>
          </div>
          <div v-if="nextBilling" class="col-12 col-md-6 text-md-right">
            <small>
              <span class="text-muted">Next Charge:</span> {{ nextBilling }}
            </small>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div v-for="plan in activePlans" :key="plan.id" class="col-12 col-lg-4 mb-3">
            <div
              class="bg-white rounded border p-3 h-100"
              :class="{
                'border-primary': plan.id === values.plan,
                'border-primary-inset': plan.id === values.plan,
              }"
              style="cursor: pointer;"
              @click="values.plan = plan.id"
            >
              <div class="d-flex justify-content-between mb-3">
                <h2 class="h5">{{ plan.name }}</h2>
                <div class="float-right text-right">
                  <div class="small">Starts at</div>
                  <span class="lead text-primary"><strong>${{ plan.price }}</strong><small class="text-muted">/mo*</small></span>
                </div>
              </div>
              <small>
                <ul class="fa-ul" style="margin-left: 1.25rem;">
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>Unlimited</strong> quotes
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>{{ plan.validationHits }}</strong> validations per month
                    <div class="text-muted">${{ plan.validationCost }} per additional</div>
                  </li>
                  <li>
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>{{ plan.supplyHits }}</strong> supplies per month
                    <div class="text-muted">${{ plan.supplyCost }} per additional</div>
                  </li>
                </ul>
              </small>
              <div>
                <span class="badge badge-primary" v-if="plan.id === $store.state.user.stripe.product">Current Plan</span>
              </div>
            </div>
          </div>
        </div>
        <small>
          <p>* All prices are in USD, exclusive of VAT. Upgrades or downgrades will take effect immediately.</p>
        </small>
      </div>
      <div class="card-footer">
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <a-button type="primary" html-type="submit" :loading="loading" :disabled="values.plan === $store.state.user.stripe.product">
              Switch Plan
            </a-button>
          </li>
          <li v-if="$store.state.user.stripe.product" class="list-inline-item">
            <a-button type="link" size="small" @click="onUnsubscribe">
              Unsubscribe
            </a-button>
          </li>
        </ul>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      disabled: false,
      values: {
        plan: this.$store.state.user.stripe.product,
      },
    }
  },

  computed: {
    nextBilling() {
      return this.$store.state.user.hits.reset_date && this.$store.state.user.hits.reset_date.split('T')[0]
    },
    activePlans() {
      return this.$store.state.plans.filter(item => item.active === true)
    },
  },

  methods: {
    onChange() {
      this.$confirm({
        title: 'Subscription Change',
        content: 'We prorate subscription changes. Please note that the pricing takes effect immediately but your hits will not reset.',
        okText: 'Confirm',
        onOk: async () => {
          await this.onChangeConfirm()
        },
        onCancel: () => {},
      })
    },
    async onChangeConfirm() {
      try {
        var { data } = await this.$httpd.put(`/subscriptions`, this.values)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      this.$store.commit('user', data)
      this.$message.success('Subscription plan has been changed.')
    },
    onUnsubscribe() {
      this.$confirm({
        title: 'Why so unhappy?',
        content: 'We’re sad to see you leave. Unsubscribing will take immediate effect and any metered uses during this period will be invoiced. Unused time won’t be prorated.',
        okText: 'Unsubscribe',
        okType: 'danger',
        onOk: async () => {
          await this.onUnsubscribeConfirm()
        },
        onCancel() {},
      })
    },
    async onUnsubscribeConfirm() {
      try {
        var { data } = await this.$httpd.delete(`/subscriptions`)

        this.values.plan = null
        this.$store.commit('user', data)
        this.$message.success('Subscription has been canceled.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }
    },
  },
}
</script>
